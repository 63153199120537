import { FC } from "react";
import styles from "./FooterHeader.module.scss";

interface FooterHeaderProps {
	headerData: {
		logo: string;
		logoAltText: string;
		emailAddress?: string;
		phoneNumber?: string;
		openingHours?: string;
		address?: string;
	};
	isECom: boolean;
}

const FooterHeader: FC<FooterHeaderProps> = ({ headerData, isECom }) => {
	return (
		<div className={styles.footerHeader}>
			<img src={headerData.logo} alt={headerData.logoAltText} />

			{isECom && (
				<>
					<a
						className={styles.secondaryLink}
						href={`mailto:${headerData.emailAddress}`}
					>
						{headerData.emailAddress}
					</a>

					<a
						className={styles.footerPhoneNumber}
						href={`tel:${headerData.phoneNumber}`}
					>
						{headerData.phoneNumber}
					</a>

					<p>{headerData.openingHours}</p>

					<address>{headerData.address}</address>
				</>
			)}
		</div>
	);
};

export default FooterHeader;
